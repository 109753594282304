<template>
  <div>
    <div ref="map" style="display: none;" />
    <validation-provider
      :name="$t(veeName)"
      :rules="{
        ...veeRules,
        placeRequired: [address, placeResults.length]
      }"
      v-slot="validationContext"
    >
      <b-form-group
        label-class="font-weight-bold"
        :label="$t(label)"
        :label-for="veeName"
      >
        <b-input-group>
          <b-form-input
            v-bind="$attrs"
            v-on="$listeners"
            debounce="500"
            :state="getValidationState(validationContext)"
            @update="search"
            @focus="isInputFocus = true"
            @blur="isInputFocus = false"
          >
          </b-form-input>
          <b-input-group-append v-if="loading" is-text>
            <b-spinner label="Small Spinner" small></b-spinner>
          </b-input-group-append>
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-input-group>
      </b-form-group>
    </validation-provider>
    <div v-if="hasError" class="text-center mt-3">
      {{ $t("noResults") }}
    </div>
    <div v-else class=" mt-3">
      <div
        v-for="(result, index) in placeResults"
        :key="index"
        class="mt-1 py-2 result-item clickable"
        @click="setPlace(result)"
      >
        <div>
          {{ result.description }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gmapsInit from "./../../plugins/gmaps.js";
export default {
  props: {
    label: {
      type: String,
      default: ""
    },
    veeName: {
      type: String,
      default: ""
    },
    veeRules: {
      type: [Object, null],
      default: null
    },
    address: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      google: null,
      sessionToken: null,
      restrictToCountries: [], // "IT"
      placeResults: [],
      hasError: false,
      isInputFocus: false,
      loading: false,
      loadingPlaceInfo: false
    };
  },
  async mounted() {
    this.google = await gmapsInit();
    this.$_autocompleteService = new window.google.maps.places.AutocompleteService();
    this.$_geocoderService = new window.google.maps.Geocoder();
    this.$_placesService = new window.google.maps.places.PlacesService(
      this.$refs.map
    );
  },
  methods: {
    search(text) {
      this.loading = true;
      this.$emit("reset");
      if (text && text.length > 0) {
        this.searchPlaces(text).then(({ predictions, status }) => {
          this.hasError =
            status !== window.google.maps.places.PlacesServiceStatus.OK;

          this.placeResults = this.hasError ? [] : predictions;
          this.loading = false;
        });
      } else {
        this.hasError = false;
        this.placeResults = [];
        this.loading = false;
      }
    },
    searchPlaces(text) {
      var self = this;
      if (this.sessionToken === null) {
        // Create a new session token.
        this.sessionToken = new window.google.maps.places.AutocompleteSessionToken();
      }
      return new Promise(resolve => {
        self.$_autocompleteService.getPlacePredictions(
          {
            input: text,
            componentRestrictions: { country: this.restrictToCountries },
            types: ["address"],
            sessionToken: this.sessionToken
          },
          (predictions, status) => {
            resolve({ predictions, status });
          }
        );
      });
    },
    setPlace(item) {
      this.loadingPlaceInfo = true;
      this.placeResults = [];
      this.hasError = false;
      if (item !== undefined) {
        const parent = this;
        if (item !== null) {
          const placeId = item.place_id;
          return new Promise(resolve => {
            parent.$_placesService.getDetails(
              {
                language: parent.language,
                placeId: placeId,
                sessionToken: parent.sessionToken,
                fields: [
                  "address_component",
                  "formatted_address",
                  "geometry",
                  "icon",
                  "name",
                  "place_id",
                  "type",
                  "vicinity",
                  "utc_offset_minutes",
                  "type"
                ]
              },
              (place, status) => {
                resolve({ place, status });
                const placeInfo = {
                  placeId: placeId,
                  streetName: "",
                  streetNumber: "",
                  postalCode: "",
                  country: "",
                  city: "",
                  countryShort: "",
                  province: "",
                  formattedAddress: place.formatted_address,
                  lat: place.geometry.location.lat(),
                  lng: place.geometry.location.lng()
                };
                place.address_components.forEach(function(addressComponent) {
                  if (addressComponent.types.includes("street_number")) {
                    placeInfo.streetNumber = addressComponent.long_name;
                  }
                  if (addressComponent.types.includes("route")) {
                    placeInfo.streetName = addressComponent.long_name;
                  }
                  if (addressComponent.types.includes("postal_code")) {
                    placeInfo.postalCode = addressComponent.long_name;
                  }
                  if (addressComponent.types.includes("country")) {
                    placeInfo.country = addressComponent.long_name;
                    placeInfo.countryShort = addressComponent.short_name;
                  }
                  if (addressComponent.types.includes("locality")) {
                    placeInfo.city = addressComponent.long_name;
                  }
                  if (
                    addressComponent.types.includes(
                      "administrative_area_level_2"
                    )
                  ) {
                    placeInfo.province = addressComponent.short_name;
                  }
                });

                if (!placeInfo.province) {
                  const locationinfo = place.address_components.find(p =>
                    p.types.includes("administrative_area_level_3")
                  );
                  placeInfo.province = locationinfo
                    ? locationinfo.short_name
                    : "";
                }

                this.$emit("setPlaceInfo", placeInfo);
                this.loadingPlaceInfo = false;
              }
            );
          });
        } else {
          this.loadingPlaceInfo = false;
        }
      } else {
        this.loadingPlaceInfo = false;
      }
    },
    getValidationState({ dirty, validated, valid, touched }) {
      if (this.isInputFocus || this.loadingPlaceInfo) return null;
      else if (dirty || validated) return valid ? null : valid;
      else return null;
    }
  }
};
</script>

<style lang="scss" scoped>
.input-group {
  /deep/ {
    .input-group-text {
      background: inherit;
      border-left: unset;
    }
  }
}
.result-item {
  border-bottom: 1px solid var(--gray-light);
}
</style>
