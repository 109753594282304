<template>
  <validation-provider
    :name="$t(veeName)"
    :rules="veeRules"
    v-slot="validationContext"
  >
    <b-form-group
      label-class="font-weight-bold"
      :label="$t(label)"
      :label-for="veeName"
    >
      <b-input-group>
        <b-form-input
          :id="veeName"
          v-bind="$attrs"
          v-on="$listeners"
          :state="getValidationState(validationContext)"
        ></b-form-input>
        <slot name="default"></slot>
        <b-form-invalid-feedback>
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </b-input-group>
    </b-form-group>
  </validation-provider>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ""
    },
    veeName: {
      type: String,
      default: ""
    },
    veeRules: {
      type: [String, Object],
      default: null
    }
  },
  methods: {
    getValidationState({ valid, touched }) {
      if (touched) return valid ? null : valid;
      else return null;
    }
  }
};
</script>

<style></style>
